import React, { FormEvent } from "react";
import { createPost, getPostsData } from "../../api";
import { PostTypes } from "../../App";
import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../features/user/userSlice";
import Loader from "../Loader/Loader";
interface FormTypes {
	title?: string;
	content?: string;
	link?: string;
}

interface InputProps {
	setPosts: React.Dispatch<React.SetStateAction<PostTypes[]>>;
}

const Input = ({ setPosts }: InputProps) => {
	const user = useAppSelector(selectUser);
	const [height, setHeight] = React.useState<string>("h-64");
	const [post, setPost] = React.useState<FormTypes>({
		title: "",
		content: "",
		link: "",
	});
	const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
	const [isAddingLink, setIsAddingLink] = React.useState<boolean>(false);

	const handleFocus = () => {
		if (height === "h-64") {
			setHeight("h-72");
		} else {
			setHeight("h-64");
		}
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		e.preventDefault();
		setPost({ ...post, [e.currentTarget.name]: e.currentTarget.value });
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setIsSubmitting(true);
		const res = await createPost({ ...post, username: user!, id: 0 });
		if (res !== "error") {
			setPost({ title: "", content: "", link: "" });
		}
		const posts = await getPostsData();
		setPosts(posts);
		setIsSubmitting(false);
	};

	return (
		<form
			className={
				"flex justify-around flex-col relative w-[40%] min-w-[500px] " +
				"bg-gray-800 rounded p-3 shadow-xl transition-all " +
				"duraction-300 flex-shrink-0 delay-100 " +
				`${height}`
			}
			onSubmit={handleSubmit}
		>
			{!isSubmitting ? (
				<div className="flex flex-col h-full p-0">
					<label className="px-2 text text-gray-700 mb-2">
						Make a post
					</label>
					<input
						className="w-full rounded p-2 mb-2 bg-gray-800 
						focus:outline-none focus:ring-2 ring-blue-500 "
						type="text"
						placeholder="Title your post"
						name="title"
						onChange={handleChange}
						value={post.title}
						disabled={isSubmitting}
					/>
					<textarea
						className={
							"group w-full resize-none rounded p-2 bg-gray-800" +
							" focus:outline-none focus:ring-2 ring-blue-500" +
							" mb-3 " +
							(height === "h-36" ? "h-1/3" : "h-1/2")
						}
						placeholder="What's on your mind?"
						onFocus={handleFocus}
						onBlur={handleFocus}
						name="content"
						onChange={handleChange}
						value={post.content}
						disabled={isSubmitting}
					></textarea>

					{isAddingLink && (
						<input
							className="w-full rounded p-2 mb-2 bg-gray-800 
							focus:outline-none focus:ring-2 ring-blue-500 "
							type="text"
							placeholder="Add a link to your image"
							name="link"
							onChange={handleChange}
							value={post.link}
							disabled={isSubmitting}
						/>
					)}
					<div className="flex flex-row">
						<button
							className="w-1/3 max-w-[150px] bg-blue-300 
							text-blue-800 py-2 px-4 font-semibold text-md 
							uppercase text-center rounded shadow mb-2 
							hover:scale-105 hover:shadow-xl transition-all 
							hover:bg-blue-800 hover:text-blue-100
							disabled:bg-blue-100 disabled:cursor-not-allowed 
							disabled:hover:text-blue-900"
							type="submit"
							disabled={
								isSubmitting ||
								post.title?.length === 0 ||
								post.content?.length === 0 ||
								(isAddingLink && post.link?.length === 0)
							}
						>
							Post
						</button>
						<button
							className="text-gray-200 mx-2 py-1 px-3 rounded 
							hover:bg-gray-900 hover:opacity-70 bg-opacity-25"
							type="button"
							onClick={() => setIsAddingLink(!isAddingLink)}
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									strokeWidth="2"
									d="M4 16l4.586-4.586a2 2 0 
									012.828 0L16 16m-2-2l1.586-1.586a2 2 0 
									012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 
									002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 
									002 2z"
								/>
							</svg>
						</button>
					</div>
				</div>
			) : (
				<Loader />
			)}
		</form>
	);
};

export default Input;
