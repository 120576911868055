import { BaseEmoji, Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import React from "react";
import { addReaction } from "../../api";
import errorSrc from "../../assets/cancel.svg";

export interface PostProps {
	id: number;
	title?: string;
	username?: string;
	content?: string;
	timestamp?: number;
	reactions?: Array<{ [key: string]: number }>;
	link?: string;
	updatePosts: () => Promise<any>;
}

const Post = ({
	id,
	title,
	username,
	content,
	timestamp,
	reactions,
	link,
	updatePosts,
}: PostProps) => {
	const [showEmoji, setShowEmoji] = React.useState<boolean>(false);

	const handleSelect = async (emoji: BaseEmoji) => {
		setShowEmoji(false);
		// send post id, username, and reaction
		await addReaction({
			postId: id,
			username: "crbellis",
			reaction: emoji.native,
		});
		await updatePosts();
	};

	const imageOnErrorHandler = (
		event: React.SyntheticEvent<HTMLImageElement, Event>
	) => {
		event.currentTarget.src = errorSrc;
		event.currentTarget.className = "h-36 mb-5";
	};

	const date = new Date(timestamp!);
	return (
		<div
			className="select-none relative w-96 min-h-[14rem] bg-gray-800 
		shadow-lg my-2 rounded "
		>
			<div
				className="flex w-full p-3 border-b border-gray-700 
			border-opacity-75 divide-x divide-gray-600 items-center"
			>
				<div className="pr-6 font-semibold text-lg">{title}</div>
				<div className="pl-6 text-gray-400 font-semibold">
					@{username}
				</div>
			</div>
			<div className="p-3">{content}</div>
			{link && (
				<div
					className="flex justify-center items-center p-3 w-full 
				pb-16"
				>
					<img
						onError={imageOnErrorHandler}
						className="h-64 w-full object-scale-down mb-5"
						src={link}
						alt={link}
					/>
				</div>
			)}
			<div>
				<div className="absolute p-3 left-0 bottom-0">
					<button
						onClick={() => setShowEmoji(!showEmoji)}
						className="py-2 px-3 rounded bg-blue-300 
						text-blue-800 font-semibold shadow-md 
						hover:bg-blue-800 hover:text-blue-100 
						transition-all
						hover:scale-105
						hover:shadow-xl"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 
								10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
							/>
						</svg>
					</button>

					<div
						className={
							"absolute w-full z-100 my-2 " +
							(showEmoji ? " visible" : " hidden")
						}
					>
						<div className="absolute z-10 opacity-100">
							<Picker
								set="apple"
								onSelect={handleSelect}
								color="darkgray"
								style={{ zIndex: 100 }}
							/>
						</div>
					</div>
				</div>
				<div className="absolute p-3 flex flex-row bottom-12">
					{reactions &&
						reactions.map((reaction, idx) => {
							return Object.keys(reaction).map((emoji) => {
								return (
									<div
										className="flex flex-row rounded-xl px-1 
										bg-gray-900 bg-opacity-75 mr-1 
										justify-center items-center"
										key={`${emoji}` + idx}
									>
										<div className="text-gray-600 px-2">
											{reaction[emoji]}
										</div>
										<div>{emoji}</div>
									</div>
								);
							});
						})}
				</div>
				<div
					className="absolute p-3 right-0 bottom-0 text-gray-700 
				text-sm"
				>
					{date.toLocaleString("en-us")}
				</div>
			</div>
		</div>
	);
};

export default Post;
