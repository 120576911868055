import React from "react";
import Post from "./components/Post/Post";
import Input from "./components/Input/Input";
import { getPostsData } from "./api";
import Loader from "./components/Loader/Loader";
import Account from "./components/Account/Account";
import loginImg from "./assets/dream.svg";
import { useAppSelector } from "./app/hooks";
import { selectUser } from "./features/user/userSlice";

export interface PostTypes {
	id: number;
	content?: string;
	timestamp?: number;
	title?: string;
	username?: string;
	reactions?: Array<{ [key: string]: number }>;
	link?: string;
}

function App() {
	const [posts, setPosts] = React.useState<Array<PostTypes>>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const user = useAppSelector(selectUser);

	// ondemand post updater - called once a user creates a new post
	const updatePosts = async (): Promise<void> => {
		try {
			let postJson = await getPostsData();
			setPosts(postJson);
			return;
		} catch (error) {
			console.log(error);
		}
	};

	React.useEffect(() => {
		const getPosts = async () => {
			try {
				let posts = await getPostsData();
				if (posts.length > 0) {
					setPosts(posts);
					setIsLoading(false);
				}
			} catch (error) {
				console.log(error);
			}
		};

		getPosts();
	}, []);

	return (
		<div className="relative w-screen">
			{user === null ? (
				<div
					className="absolute z-20 w-screen h-screen 
				bg-gray-900 backdrop-blur-3xl flex justify-center 
				items-center flex-col"
				>
					<div className="text-4xl text-white text-left font-bold">
						Login to continue
					</div>
					<img src={loginImg} alt="dreamer" className="w-4/5 h-4/5" />
				</div>
			) : null}
			<div
				className="w-full h-screen mr-auto bg-gray-900 
			text-white p-10 flex flex-col justify-start items-center 
			overflow-y-scroll"
			>
				<Input setPosts={setPosts} />
				{posts.length > 0 ? (
					posts.map((post, idx) => {
						return (
							<div key={idx}>
								<Post {...post!} updatePosts={updatePosts} />
							</div>
						);
					})
				) : isLoading ? (
					<Loader />
				) : (
					<div
						className="text-gray-100 font-semibold text-center
					mt-3"
					>
						Sorry, it looks like there are no posts at the moment.
						<br />
						Try adding a post by writing in the form above!
					</div>
				)}
			</div>
			<div className="fixed top-0 z-30 right-0 text-white">
				<Account />
			</div>
		</div>
	);
}

export default App;
