import React from "react";
import { selectUser, setUsername } from "../../features/user/userSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";

const Account = () => {
	const [inputUsername, setInputUsername] = React.useState<string>("");
	const user = useAppSelector(selectUser); // returns username
	const dispatch = useAppDispatch(); // dispatches actions to Redux

	// update component state with current entry
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		setInputUsername(e.currentTarget.value);
	};

	// dispatch state to Redux
	const handleSignIn = () => {
		if (inputUsername) {
			dispatch(setUsername(inputUsername));
		}
	};
	return (
		<div
			className={
				"lg:flex w-full z-30 p-10 min-w-[320px] h-screen bg-gray-800 " +
				"bg-opacity-10 rounded " +
				(user !== null && " sm:hidden")
			}
		>
			{user === null ? (
				<form className="w-full flex flex-col" onSubmit={handleSignIn}>
					<label className="text-sm text-gray-700 mb-2">
						Username
					</label>
					<input
						type="text"
						placeholder="username"
						className="rounded p-1 text-white bg-gray-800 
						opacity-100 mb-4 shadow px-2"
						pattern="[a-zA-Z0-9]+"
						onChange={handleChange}
					/>
					<button
						type="submit"
						className="w-full bg-blue-300 text-blue-900 p-2 
						rounded shadow-xlhover:bg-blue-800 hover:text-blue-100 
						transition-all hover:scale-105 hover:shadow-2xl
						disabled:bg-blue-100 disabled:cursor-not-allowed 
						disabled:hover:text-blue-900
						"
						disabled={inputUsername?.length === 0}
					>
						Sign In
					</button>
				</form>
			) : (
				<div className="text-xl text-gray-400 mb-2">@{user}</div>
			)}
		</div>
	);
};

export default Account;
