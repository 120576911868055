import { PostTypes } from "./App";
const baseURL = "https://cf-submission.bellischase.workers.dev/";

export const getPostsData = async (): Promise<Array<PostTypes>> => {
	try {
		const res = await fetch(baseURL + "posts");
		return res.json();
	} catch (error) {
		return [];
	}
};

export const createPost = async (
	post: PostTypes
): Promise<string | undefined> => {
	try {
		const res = await fetch(baseURL + "posts", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(post),
		});
		return res.text();
	} catch (error) {
		return error as string;
	}
};

export const getRawReactions = async () => {
	try {
		const data = await fetch(baseURL + "raw-reactions");
		return await data.json();
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const addReaction = async (reaction: {
	postId: number;
	reaction: string;
	username: string;
}): Promise<string | undefined> => {
	try {
		let data = await getRawReactions();
		console.log(data);
		let json = [];
		if (data) {
			json = JSON.parse(await getRawReactions());
		}
		json.push(reaction);
		if (json !== undefined) {
			const res = await fetch(baseURL + "reaction", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(json),
			});
			return res.json();
		}
	} catch (error) {
		console.log(error);
		return;
	}
};
