import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserState {
	username: string | null;
}

const initialState: UserState = {
	username: null,
};

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		setUsername: (state, action: PayloadAction<string>) => {
			state.username = action.payload;
		},
	},
});

export const { setUsername } = userSlice.actions;
export const selectUser = (state: RootState) => state.user.username;
export default userSlice.reducer;
